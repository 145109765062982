<template lang="pug">
.stats.fixed
  img(ref="img" src="~@/assets/img-effect/effect.jpg")
.canvas
  canvas(ref="canvas")
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { loadImg, resize } from './ImageEffect/common';
import createShineCommand from '@/commands/shine';
import createREGL from 'regl';

export default {
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    let regl;

    onMounted(async () => {
      resize(canvas.value);
      regl = createREGL({ canvas: canvas.value });

      const imgEl = await loadImg(img.value);
      const draw = createShineCommand(regl, { imgEl });
      const start = () => {
        regl.frame(({ time }) => draw(time));
      };
      start();
    });

    onBeforeUnmount(() => regl?.destroy());
    return { canvas, img };
  },
};
</script>

<style lang="stylus">
@import './ImageEffect/common.styl';
canvas
  width 100vw
  height 100vh
</style>
