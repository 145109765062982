import vert from './shine.vert';
import frag from './shine.frag';

const devicePixelRatio = Math.max(window.devicePixelRatio, 2);

export default function createShineCommand(regl, { imgEl: img }) {
  console.log(img);
  return regl({
    vert,
    frag,
    // elements: [
    //   [0, 1],
    //   [0, 2],
    //   [0, 3],
    //   [0, 4],
    //   [1, 2],
    //   [1, 3],
    //   [1, 4],
    //   [2, 3],
    //   [2, 4],
    //   [3, 4],
    // ],
    elements: [0, 2, 1, 0, 2, 3],
    uniforms: {
      // u_pixelRatio: devicePixelRatio,
      // u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      color: [1, 1, 0, 1],
      u_texture: regl.texture({ data: img, flipY: true }),
      u_time: regl.context('time'),
      u_speed: 0.15,
      u_offset: 0.1,
    },
    // count: 3,
    attributes: {
      // a_position: new Array(5).fill().map((v, i) => {
      //   const theta = (2.0 * Math.PI * i) / 5;
      //   return [Math.sin(theta), Math.cos(theta)];
      // }),
      a_position: [
        [-1, 1],
        [-1, -1],
        [1, -1],
        [1, 1],
      ],
    },
    // lineWidth: 1,
  });
}
